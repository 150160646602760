import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const CysCardHelper = defineComponent({
    name:'CysCard',
    title:'承运商',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            addStatus:false,
            showUpload:false,
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/cys"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                type: [
                    {required: true, message: "请选择类型", trigger: "blur" }
                ],
                name: [
                    {required: true, message: "请输入承运商名称", trigger: "blur" }
                ],
                contacts: [
                    {required: true, message: "请输入联系人", trigger: "blur" }
                ],
                telephone: [
                    // {required: true, message: "请输入联系电话", trigger: "blur" }
                    { required: true,validator: utils.$$str.checkPhone, trigger: 'blur' }
                ],
                address: [
                    {required: true, message: "请输入联系地址", trigger: "blur" }
                ],
                date: [
                    {required: true, message: "请选择合作日期", trigger: "blur" }
                ],
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'type'==params.comboId){
                     return [{value:0,label:'个人'},{value:1,label:'机构'},{value:2,label:'公司'}]
                }
                if(params && 'joinType'==params.comboId){
                     return [{value:0,label:'合作'},{value:1,label:'自营'}]
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue,selectId)=>{
            if('type'==selectId){
            }
        }
        const buildUploadInitParams=computed(()=>{
            return (params) => {
                return {
                    saveType:1,
                    listType:'picture-card',
                    showFileList:true,
                    showInDialog:true,
                    accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
                    uploadModule:'cys',
                    uploadType:params.uploadType
                }
            }
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if('/add'==addOrLoad){
                dataObj.addStatus=true;
            }else{
                dataObj.showUpload=true;
                if(data.data.status==1 || data.disabled){
                    dataObj.disabled=true;
                    dataObj.refMap.get('hzxy').disableUpload(true);
                    dataObj.refMap.get('sfzFront').disableUpload(true);
                    dataObj.refMap.get('sfzBack').disableUpload(true);
                    dataObj.refMap.get('yyzz').disableUpload(true);
                }else if(data.data.status==0)dataObj.disabled=false;
            }
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'hzxy',files:data.data.hzxyList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'sfzFront',files:data.data.sfzFrontList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'sfzBack',files:data.data.sfzBackList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'yyzz',files:data.data.yyzzList});
        }
        //-----------------------------upload---------------------------
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData,params,uploadInst)=>{
            if((uploadInst.uploadParams.saveType)==0){
                //不采用第三方文件服务器，formData才有append方法，否则formData就是一个普通的对象，没有append方法
                formData.append('belongMaxId',dataObj.form.id);
            }else{
                formData.belongMaxId=dataObj.form.id;
            }
        }
        //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
        const afterResult=(res,uploadInst)=>{
            utils.$$lghdUtils.dealAfterUpload({res:res,uploadInst:uploadInst});
        }

        //-----------------------------upload---------------------------
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const sassClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sureHandler=async (modelInst)=>{
            let res=await utils.$$lghdUtils.claimDialogSure({modelInst:modelInst,proxy:proxy,url: dataObj.compParams.modelPath + "/custom",id:dataObj.form.id});
            return res;
        }
        const afterSaveHandler=(res,contentName,engineInst)=>{
            if(res.data.status==1){
                dataObj.disabled=true;
            }else{
                dataObj.disabled=false;
            }
            dataObj.refMap.get('hzxy').disableUpload(dataObj.disabled);
            dataObj.refMap.get('sfzFront').disableUpload(dataObj.disabled);
            dataObj.refMap.get('sfzBack').disableUpload(dataObj.disabled);
            dataObj.refMap.get('yyzz').disableUpload(dataObj.disabled);
            return true;
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,startHandler,stopHandler,
            buildUploadInitParams,afterResult,buildUploadParams,sassClaimHandler,sureHandler,afterSaveHandler
         
        }
    }
});
export default CysCardHelper;